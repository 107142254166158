import * as React from "react"
import { Helmet } from "react-helmet"

function Seo() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Interaktywne zastosowanie opasek RFID w celu gamifikacji parków rozrywki"
      />
      <meta name="keywords" content="Gry, Gamifikacja, Astroflap" />
      <title>OakGames - Astroflap</title>
      <meta lang="pl" />
      <meta property="og:title" content="Checkout Astroflap" />
      <meta
        property="og:description"
        content="Arcade game in which we try to avoid as many obstacles as possible by using our jetpack and performing space stunts"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://oakgames.it/games/astroflap" />
      <meta
        property="og:image"
        content="https://oakgames.it/astroflap_screen.png"
      />
    </Helmet>
  )
}

export default Seo
