import * as React from "react"
import Demo from "../../components/Demo"
import { UnityContext } from "react-unity-webgl"
import { useEffect, useState } from "react"
import Seo from "../../seo"

const unityContextAstroFlap = new UnityContext({
  loaderUrl: "/games/astroflap/AstroFlapBuild.loader.js",
  dataUrl: "/games/astroflap/AstroFlapBuild.data",
  frameworkUrl: "/games/astroflap/AstroFlapBuild.framework.js",
  codeUrl: "/games/astroflap/AstroFlapBuild.wasm",
})

function handleOnClickFullscreen() {
  unityContextAstroFlap.setFullscreen(true)
}

function AstroflapGame() {
  const [progression, setProgression] = useState(0)

  useEffect(function () {
    unityContextAstroFlap.on("progress", function (progression) {
      setProgression(progression)
    })
    unityContextAstroFlap.on("share", function (points) {
      window
        .open(
          "https://www.facebook.com/sharer/sharer.php?u=https://oakgames.it/games/astroflap&quote=My score is: " +
            points +
            " , can you beat me?",
          "_blank"
        )
        .focus()
    })
    unityContextAstroFlap.setFullscreen(true)
  }, [])

  return (
    <>
      <Seo />
      <Demo
        progression={progression}
        unityContextGame={unityContextAstroFlap}
        handleFullScreen={handleOnClickFullscreen}
      />
    </>
  )
}

export default AstroflapGame
